.calendar {
  width: 100%;
}
.calendarRow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CalendarBody {
  width: 100%;
  height: 89%;
}
/* ****년  **월 */
.DateHederContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.DateHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 일 월 화 수 목 금 토 */
.DateDaysContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateDays {
  flex: 1;
  text-align: center;
  padding: 10px 0;
}
/* dayss */
.cols {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  font-size: 17px;
  font-weight: 700;
}
.Disabled:disabled {
  color: inherit;
}
.selected {
  background-color: #3a5f96;
  color: white;
}
.selected:hover {
  background-color: #104596;
  color: white;
}
.valid:hover {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: none;
  background: silver;
}
.today {
  border: 1px solid #3a5f96;
}
.today:hover {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: none;
  background: rgba(192, 192, 192, 0.479);
}
