.ShipInspection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid silver;
  padding: 10px;
}
.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderContainer {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  padding-top: 10px;
}
.HeaderContainer > p {
  flex: 1;
  text-align: center;
  font-weight: 600;
}
.row1 {
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row1 > span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clum2 {
  flex-direction: column;
  padding: 6px 0;
  border-left: 1px solid #8b8b8b;
}
.clum2 :first-child {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #8b8b8b;
  padding-bottom: 6px;
}
.clum2 :last-child {
  text-align: center;
  width: 100%;
  padding-top: 6px;
}
