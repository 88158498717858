.RowContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #3a5f96;
}
.Title {
  flex: 3fr;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 15px 5px;
  font-weight: 700;
  color: #3a5f96;
  white-space: pre-line;
  text-align: center;
}
.LineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2px;
}
.Line1 {
  height: 16px;
  width: 2px;
  background-color: #3a5f96;
}
.Line2 {
  display: flex;
  align-items: center;
  width: 1px;
  justify-content: center;
  background-color: #3a5f96;
}
.Line3 {
  display: flex;
  align-items: center;
  width: 2px;
  justify-content: center;
  background-color: #3a5f96;
}
.DescInput {
  flex: 1;
  width: 70%;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  white-space: pre-line;
}
.DescInput2 {
  flex: 1;
  width: 70%;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  white-space: pre-line;
}
.Desc {
  flex: 1;
  width: 70%;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
}
.Desc1 {
  flex: 1;
  width: 35%;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
  line-height: 25px;
}
.Desc2 {
  flex: 1;
  width: 35%;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
  line-height: 25px;
}
.Desc3 {
  flex: 1;
  width: 50%;
  padding: 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
}
.Desc4 {
  width: 100%;
  padding: 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
}
.select1 {
  flex: 1;
  width: 70%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
}
.select {
  flex: 1;
  width: 70%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  white-space: pre-line;
}
