.appHeader {
  margin-top: 20px;
  background-color: #fff;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
}

.loginContainer {
  gap: 20px;
}

.loginMore {
  width: 100%;
  /* max-width: 430px; */
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  /* border: 1px solid red; */
}

.autoLogin {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.loginButton {
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 17px;
}
