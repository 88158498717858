.btn {
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px 8px;
  border: 1px solid var(--mainColor);
  font-size: 16px;
  background-color: var(--whiteColor);
  line-height: 16px;
  font-family: "Noto Sans KR";
}
.click {
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  padding: 16px 8px;
  border: 2px solid var(--mainColor);
  font-size: 16px;
  background-color: var(--whiteColor);
  line-height: 16px;
  font-family: "Noto Sans KR";
}
